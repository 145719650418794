import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    imports: [CommonModule],
    selector: 'app-generic-button-with-arrow',
    templateUrl: './genericButtonWithArrow.html',
    styleUrls: ['./genericButtonWithArrow.scss']
})
export class GenericButtonWithArrow {
  @Input() label: string = 'Button';
  @Output() onClick = new EventEmitter<any>();
  @Input() minWidth?: string;
  @Input() width?: string;
  @Input() vertical?: boolean;
  @Input() position?: 'left' | 'right';
  @Input() secondary?: boolean;
  @Input() color_string: string = 'blue_button';
  
  public handleClick() {
    this.onClick.emit();
  }
}
